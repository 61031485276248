<template>
    <modal ref="modalAsignacion" titulo="Asignar entregas" tamano="modal-xl" no-aceptar adicional="Asignar" @adicional="asignar()">
        <div class="row mx-0 justify-center">
            <div class="col-auto mt-3 pt-1 px-2">
                <img :src="deliveryImagen" width="38" height="38" class="obj-cover bg-light-f5 rounded-circle" />
            </div>
            <div class="col-3">
                <p class="f-14 text-general pl-3">Delivery</p>
                <el-select v-model="delivery" class="w-100" :clearable="true" @change="onChangeDelivery()">
                    <el-option
                    v-for="(item, i) in deliveries"
                    :key="i"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-2">
                <p class="f-14 text-general pl-3">Día</p>
                <el-date-picker
                v-model="fecha"
                class="w-100"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd MMM yyyy"
                :clearable="false"
                @change="porCedisFecha()"
                />
            </div>
            <div class="col-auto text-general d-middle pt-3">
                Seleccione los pedidos que se asignarán a este Delivery
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general ref="tablaGeneral" :data="pedidos" activar-seleccion-multiple :mostrar-buscador="false" :alto="altoTabla" :usar-paginacion="false" @seleccion="data => onSelect(data)">
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        label="Pedido"
                        class-name=" text-general f-17"
                        width="100"
                        >
                            <template slot-scope="{ row }">
                                <p class="text-general2">
                                    {{ row.id }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Delivery"
                        class-name="text-center text-general f-17"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ row.delivery_nombre }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Leechero"
                        class-name="text-center text-general f-17"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ row.tienda_nombre }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Cliente"
                        class-name="text-center text-general f-17"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ row.nombre_cliente }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Valor"
                        class-name="text-center text-general f-17"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ convertMoney(row.valor_final) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Estado"
                        class-name="text-center text-general f-17"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ row.estado }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Entrega"
                        class-name="text-center text-general f-17"
                        width="200"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ row.entrega }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Cedis"
                        class-name="text-center text-general f-17"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <p class="text-general2">
                                    {{ row.cedis_nombre }}
                                </p>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
    </modal>
</template>

<script>
import tablero from '~/services/pedidos/tablero'
import moment from 'moment'
import { mapGetters } from 'vuex'

const estados = {
    0: { nombre: 'Borrador', },
    1: { nombre: 'Sin Confirmar', },
    2: { nombre: 'Confirmado', },
    201: { nombre: 'Alistado', },
    202: { nombre: 'Empacado', },
    3: { nombre: 'Enviado', },
}

export default {
    data(){
        return {
            value1: '',
            data: {},
            pedidos: [],
            deliveries: [],
            delivery: '',
            selected: [],
            fecha: '',
            altoTabla: 'calc(100vh - 538px)'
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis'
        }),
        deliveryImagen(){
            const { imagen = '/img/no-imagen/sin_user.png' } = this.deliveries.find(el => el.id == this.delivery) ?? {}
            return imagen
        },
    },
    mounted(){
        this.changeHeight();
    },
    methods: {
        async toggle(data){
            this.delivery = ''
            this.data = data
            this.fecha = data.fecha
            // await this.onShow()
            await this.buscarDeliveries()
            await this.porCedisFecha()
            this.$refs.modalAsignacion.toggle();
        },
        changeHeight(){
            setTimeout(() => {
                let height = window.innerHeight
                this.altoTabla = height <= 680 ? 'calc(100vh - 436px)' : 'calc(100vh - 538px)'
            }, 0);
        },
        async porCedisFecha(){
            try {
                const params = {
                    cedi: this.id_cedis,
                    fecha: this.fecha
                }
                const { data } = await tablero.porCedisFecha(params)
                this.data = {}
                this.delivery = ''
                this.$refs.tablaGeneral.clearSelection()
                this.selected = []
                this.pedidos = []
                if(data.pedidos.length){
                    this.data = data.pedidos[0]
                    await this.onShow()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async onShow(){
            try {

                let pedidos = this.data.horarios.reduce((acc, el) => {
                    if (el.pedidos.length){
                        let idPedidos = el.pedidos.map(el => el.id)
                        acc = [...acc, ...idPedidos]
                        return acc
                    }
                    return acc
                }, []).join()

                const { data } = await tablero.showPedidos({pedidos})

                this.pedidos = data.map(el => {
                    const horario = this.data.horarios.find(ele => ele.pedidos.some(elm => elm.id == el.id))
                    let fechaI =  this.formatDate(`${this.data.fecha} ${horario.horario}:00`)
                    let fechaF =  this.formatDate(`${this.data.fecha} ${horario.fin}:00`)
                    el.estado = estados[el.estado].nombre || el.estado[0].nombre
                    el.entrega = `${fechaI} - ${fechaF}`
                    return el
                })
                /* setTimeout(() => {
                    this.$refs.tablaGeneral.toggleRowSelection(this.pedidos[0])
                }, 500); */
                // console.log(this.$refs.tablaGeneral.toggleRowSelection(this.pedidos[0]));
            } catch (error){
                this.error_catch(error)
            }
        },
        onSelect(data){
            this.selected = data
        },
        formatDate: (fecha) => moment(fecha).format('h A'),
        async asignar(){
            if(!this.delivery) return this.notificacion('', 'Debe seleccionar un delivery', 'error')
            if(!this.selected.length){
                return this.$refs.modalAsignacion.toggle();
            }
            let anyDiferent = this.selected.some(el => el.id_delivery != this.delivery)

            if (anyDiferent){

                const pedidos = this.selected.filter(el => el.id_delivery != this.delivery).map(el => el.id)
                const obj = {
                    pedidos,
                    delivery: this.delivery
                }
                const { data } = await tablero.asignarMultiple(obj)
                if (data.exito){
                    this.notificacion('', 'Asignados correctamente', 'success')
                    this.$emit('listar')
                }
            }
            return this.$refs.modalAsignacion.toggle();

        },
        async buscarDeliveries(){
            try {
                const params = {
                    cedis: this.id_cedis
                }
                const { data } = await tablero.buscarDeliveries(params);
                this.deliveries = data
            } catch (error){
                this.error_catch(error)
            }
        },
        onChangeDelivery(){
            this.$refs.tablaGeneral.clearSelection()
            this.selected = []
            this.pedidos.forEach((el, index) => {
                if(el.id_delivery == this.delivery){
                    this.$refs.tablaGeneral.toggleRowSelection(index)
                }
            })
        }


    }
}
</script>
